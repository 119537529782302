import React from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LoadingLists = () => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography style={{ fontWeight: '600', color: '##4E2952' }} color="primary" variant="h6">
        {t('general.show_more')}
      </Typography>
      <KeyboardArrowDown style={{ fontSize: 70 }} color="primary" />
    </div>
  );
};
export default LoadingLists;

