import React from 'react';
import { Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import { colorText, trackEventMatomo } from 'js/library/utils/helpers';

export const GoBack = (props) => {
  const location = props.location || '';
  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  return (
    <div>
      <Typography
        style={{ color: '#0089FF', cursor: 'pointer' }}
        variant="body2"
        onClick={() => {
          trackEventMatomo(location, 'tocar', 'botao', 'voltar');
          window.history.back();
        }}
      >
        <ArrowBackIos
          color={infoSeuClube ? colorText(infoSeuClube.colorPrimary) : 'primary'}
          style={{
            fontSize: '1.2rem',
            margin: '0.5rem',
          }}
        />
        {/* Retornar */}
      </Typography>
    </div>
  );
};

export default GoBack;

