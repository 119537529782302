import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';

import {
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import QRCode from 'qrcode.react';

import GoBack from 'js/containers/GoBack.js';
import {
  getLastUserInfo,
  encrypt,
  detectmob,
  formatPhone,
  getUrlVariables,
  trackEventMatomo,
  trackEventMatomoVisit,
  getClubIdEnvironment,
  colorText,
} from 'js/library/utils/helpers';

import placeholderItem from 'styles/assets/placeholder/placeholder.gif';
import couponImg from 'styles/assets/cuponStar.png';
import mobilePhoneImg from 'styles/assets/mobile-phone.svg';

import { getStorePlacesItem_v2 } from 'js/library/utils/API/getStorePlacesItem_v2.js';
import { getWallet_v1 } from 'js/library/utils/API/getWallet_v1';
import { downloadImage } from 'js/library/services/StorageManager.js';
import { apiOrder } from 'js/library/utils/API/apiOrder';
import { ModalCoupon } from './ModalCoupon/ModalCoupon';
import { getUserInfoBasic_v1 } from 'js/library/utils/API/getUserInfo_v1';
import { Box, IconButton } from '@mui/material';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { theme } from 'index';
import { getBalance_v1 } from 'js/library/utils/API/getBalance_v1';
import { useContext } from 'react';
import { SignUpContext } from 'js/context/SignUpContext';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { ModalVoucher } from './ModalVoucher/ModalVoucher';
import { apiConsumeVoucher } from 'js/library/utils/API/apiConsumeVoucher';

export function Store(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Promocao';
    trackEventMatomoVisit('Promocao');
  }, []);

  const { t } = useTranslation();

  const userInfo = getLastUserInfo();
  const isMobile = detectmob();

  const [storeInfo, setStoreInfo] = useState([]);

  const [thumbnail, setThumbnail] = useState(placeholderItem);
  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));
  const [colorPrimary, setColorPrimary] = useState('');
  const [colorSecondary, setColorSecondary] = useState('');
  const [storeURL, setStoreURL] = useState('');
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [qrCode, setQrCode] = useState(null);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [orderReply, setOrderReply] = useState(null);
  const [isModalCouponOpen, setIsModalCouponOpen] = useState(false);
  const [isModalVoucherOpen, setIsModalVoucherOpen] = useState(false);
  const [voucherResponse, setVoucherResponse] = useState('');
  const [id, setId] = useState('');
  const [hasKeywordsConsumption, setKeywordsConsumption] = useState(false);
  const [loadingStoreData, setLoadingStoreData] = useState(true);

  const { setPoints } = useContext(SignUpContext);

  useEffect(() => {
    try {
      const clubeId = getClubIdEnvironment();
      if (infoSeuClube === null || infoSeuClube === undefined) {
        (function seuClube() {
          getSeuClube(clubeId).then((dataClube) => {
            if (dataClube !== undefined) {
              setColorPrimary(dataClube.colorPrimary);
              setColorSecondary(dataClube.colorSecondary);
            }
          });
        })();
      }
    } catch (error) {
      console.log('API ERROR', error);
    }
  }, [infoSeuClube]);

  useEffect(() => {
    setLoadingStoreData(true);
    try {
      function getStore(id) {
        getStorePlacesItem_v2(id, null, userInfo.uId).then((dataStore) => {
          if (dataStore && dataStore.storeItem !== null) {
            dataStore.storeItem.thumbnail = dataStore.storeItem.thumbnail.replace(
              'triibomania-premios/',
              ''
            );

            const folder =
              dataStore.storeItem.type === 'product' ? 'triibomania-premios' : 'promocao';

            downloadImage(folder, dataStore.storeItem.thumbnail)
              .then((thumb) => {
                setThumbnail(thumb);
              })
              .catch((error) => {
                return error;
              });
            if (!userInfo.isAnonymous) {
              let cellPhone = userInfo.contactList;
              cellPhone = cellPhone.filter((item) => {
                return item.type === 'cellPhone';
              });

              const contactUser = {
                contactList: [...cellPhone],
              };

              getUserInfoBasic_v1(['getUserInfo'], contactUser).then((user) => {
                function hasCommonElements(arr1, arr2) {
                  if (arr2.length === 0) return true;

                  const set1 = new Set(arr1);
                  return arr2.some((element) => set1.has(element));
                }

                const userKeys = user?.keyWordsSetConsumption ?? [];
                const storeKeys = dataStore?.storeItem?.keyWordsSetConsumption ?? [];

                setKeywordsConsumption(hasCommonElements(userKeys, storeKeys));
                setLoadingStoreData(false);
              });
            }

            setStoreInfo(dataStore.storeItem);
            setStoreURL(dataStore.storeItem.url);

            const qrCodeValue = encrypt(
              userInfo.uId + '|' + dataStore.storeItem.templateId,
              cfac22('CRYPTO_KEY')
            );
            setQrCode(qrCodeValue);
            setId(id);
          } else setStoreInfo(false);
        });
      }
      if (getUrlVariables().id !== id && storeInfo !== null) getStore(getUrlVariables().id);
    } catch (error) {
      setStoreInfo(false);
    }
    // eslint-disable-next-line
  }, [id, userInfo.uId, storeInfo]);

  useEffect(() => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 1000,
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      () => {
        setLatitude(0);
        setLongitude(0);
      },
      options
    );
  }, []);

  useEffect(() => {
    getWallet_v1(userInfo.uId).then((wallet) => {
      setTotalPoints(wallet.totalPoints);
    });
  }, [userInfo.uId]);

  function orderStore(orderType) {
    if (userInfo.isAnonymous) {
      props.setOpenState(true);
    } else if (infoSeuClube.chargeType && !userInfo.userProfile)
      props.setOpenStateSubscription(true);
    else {
      setLoadingOrder(true);

      if (!hasKeywordsConsumption) {
        toast.error(storeInfo.message ?? 'Você não tem permissão para usar essa promoção', {
          position: 'bottom-right',
        });
        setLoadingOrder(false);
      } else if (storeInfo.voucherPaymentTemplates) {
        // resgatar com voucher
        apiConsumeVoucher(userInfo.uId, userInfo.triiboId, storeInfo.id)
          .then((res) => {
            setVoucherResponse(res?.data?.result?.orderPayment?.itemId);
            setIsModalVoucherOpen(true);
            setLoadingOrder(false);
          })
          .catch((error) => {
            console.error('Ocorreu um erro ao tentar resgatar um voucher: ', error);
            toast.error(`${error.response.data.error}`);
            setLoadingOrder(false);
          })
          .finally(() => {
            setLoadingOrder(false);
          });
      } else {
        // resgatar por pontos
        apiOrder(
          storeInfo.type === 'product' ? storeInfo.id : storeInfo.templateId,
          userInfo.triiboId,
          latitude,
          longitude,
          userInfo.triiboId,
          orderType
        )
          .then(() => {
            // setLoadingOrder(false);
            setTimeout(() => {
              getBalance_v1(userInfo.uId, userInfo.triiboId).then((balanceReceived) => {
                setPoints(
                  balanceReceived.consolidateBalance && balanceReceived.consolidateBalance.total
                );
              });
              setLoadingOrder(false);
              setOrderReply(true);
            }, 8000);
          })
          .catch(() => {
            setLoadingOrder(false);
            setOrderReply(false);
          });
      }
    }
  }

  function handleOpenModal() {
    setIsModalCouponOpen(true);
  }

  function handleCloseModal() {
    setIsModalCouponOpen(false);
  }

  function redirectPromotionPage() {
    window.open(storeURL);
  }

  function redirectUserForPage() {
    trackEventMatomo('Promocao', 'tocar', 'botão', 'Infomar');
    sessionStorage.setItem('loginOption', '3');
    sessionStorage.setItem('loginRedirect', document.location.pathname + document.location.search);
    props.setOpenState(true);
  }

  if (storeInfo.length < 1 && !storeURL) {
    return (
      <Box
        className="paper-container"
        sx={{
          height: '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress
          style={{ width: '60px', height: '60px', color: colorText(theme.palette.primary.main) }}
        />
      </Box>
    );
  }

  return (
    <div className="paper-container">
      <ModalCoupon
        storeInfo={storeInfo}
        isOpen={isModalCouponOpen}
        onRequestClose={handleCloseModal}
        storeUrl={storeURL}
      />

      <ModalVoucher
        voucherId={voucherResponse}
        isOpen={isModalVoucherOpen}
        setIsOpen={setIsModalVoucherOpen}
      />

      {storeInfo === false ? (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h6">{t('general.did_not_find_were_looking_for')}</Typography>

          <Button
            onClick={() => {
              window.history.back();
              trackEventMatomo('Promocao', 'tocar', 'botao', 'voltar');
            }}
            variant="contained"
            color="primary"
            style={{
              background: infoSeuClube ? infoSeuClube.colorPrimary : colorPrimary,
              fontWeight: 900,
              color: infoSeuClube ? infoSeuClube.colorSecondary : colorSecondary,
              textTransform: 'none',
            }}
          >
            {t('general.back')}
          </Button>
        </div>
      ) : (
        <div>
          <GoBack />

          <img src={thumbnail} alt="thumbnail" style={{ width: '100%' }} />

          <Typography style={{ fontWeight: 'bolder', color: 'black' }} variant="h6" gutterBottom>
            {storeInfo.detailsTitle ?? storeInfo.title}
          </Typography>

          <Typography
            color="secondary"
            variant="subtitle2"
            style={{ whiteSpace: 'pre-line', color: 'black' }}
          >
            {storeInfo.detailsDescription ?? storeInfo.description}
          </Typography>

          <div style={{ padding: '1rem', textAlign: 'center' }}>
            {userInfo.isAnonymous ? (
              <>
                <Typography
                  color="secondary"
                  variant="subtitle2"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {t('general.to_take_advantage_provide_name_and_phone')}
                </Typography>
                <Button
                  onClick={redirectUserForPage}
                  fullWidth={true}
                  style={{
                    background: infoSeuClube ? infoSeuClube.colorPrimary : colorPrimary,
                    fontWeight: 900,
                    color: infoSeuClube ? infoSeuClube.colorSecondary : colorSecondary,
                    textTransform: 'none',
                  }}
                  // color="primary"
                  variant="contained"
                  component="span"
                >
                  <Typography style={{ fontWeight: 'bold' }} align="center" variant="h5">
                    <span
                      style={{
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={mobilePhoneImg}
                        alt="logo celular"
                        style={{
                          width: '1.8rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '0.5rem',
                        }}
                      />
                      {t('general.inform')}
                    </span>
                  </Typography>
                </Button>
              </>
            ) : storeInfo.type === 'businessPartner' ? (
              !isMobile && storeURL.indexOf('tel://') >= 0 ? (
                <Typography variant="subtitle1" color="secondary" gutterBottom display="block">
                  {t('general.call_to')}
                  <br />
                  {formatPhone(storeURL.split('//')[1])}
                </Typography>
              ) : (
                <Button
                  fullWidth={true}
                  style={{
                    background: infoSeuClube ? infoSeuClube.colorPrimary : colorPrimary,
                    fontWeight: 900,
                    color: infoSeuClube ? infoSeuClube.colorSecondary : colorSecondary,
                    textTransform: 'none',
                    marginTop: '40px',
                  }}
                  color="primary"
                  variant="contained"
                  component="span"
                  disabled={storeURL.length < 20 || loadingStoreData}
                  onClick={() => {
                    if (!hasKeywordsConsumption) {
                      toast.error(
                        storeInfo.message ?? 'Você não tem permissão para usar essa promoção',
                        {
                          position: 'bottom-right',
                        }
                      );
                      setLoadingOrder(false);
                    } else if (infoSeuClube.chargeType && !userInfo.userProfile)
                      props.setOpenStateSubscription(true);
                    else {
                      storeInfo.hasOwnProperty('checkoutCode')
                        ? handleOpenModal()
                        : redirectPromotionPage();
                    }
                  }}
                >
                  {storeURL.length < 20 || loadingStoreData ? (
                    <CircularProgress style={{ color: colorText(theme.palette.primary.main) }} />
                  ) : (
                    <Typography style={{ fontWeight: 'bold' }} align="center" variant="h5">
                      <span
                        style={{
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={couponImg}
                          alt=""
                          style={{
                            color: '#FFF',
                            width: '2rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '1rem',
                          }}
                        />
                        {storeInfo.buttonLabel ? storeInfo.buttonLabel : t('general.go_to_site')}
                      </span>
                    </Typography>
                  )}
                </Button>
              )
            ) : storeInfo.type === 'product' ? (
              <>
                {storeInfo.showButtonDescription ? (
                  <Typography align="center" variant="subtitle1" style={{ marginTop: '40px' }}>
                    {storeInfo.buttonDescription
                      ? storeInfo.buttonDescription
                      : t('general.take_advantage_of_your_triibo_points')}
                  </Typography>
                ) : null}
                <Button
                  fullWidth={true}
                  style={{
                    background:
                      loadingOrder || (storeInfo.type === 'product' && totalPoints < storeInfo.cost)
                        ? '#d3d3d3'
                        : infoSeuClube
                        ? infoSeuClube.colorPrimary
                        : colorPrimary,
                    fontWeight: 900,
                    color:
                      loadingOrder || (storeInfo.type === 'product' && totalPoints < storeInfo.cost)
                        ? '#a9a9a9'
                        : infoSeuClube
                        ? infoSeuClube.colorSecondary
                        : colorSecondary,
                    textTransform: 'none',
                    marginTop: '40px',
                  }}
                  color="primary"
                  variant="contained"
                  component="span"
                  disabled={
                    loadingOrder ||
                    (storeInfo.type === 'product' && totalPoints < storeInfo.cost) ||
                    loadingStoreData
                  }
                  onClick={() => {
                    orderStore('store');
                    trackEventMatomo('Produto', 'tocar', 'botao', 'Resgatar produto!');
                  }}
                >
                  {loadingOrder || loadingStoreData ? (
                    <CircularProgress style={{ color: colorText(theme.palette.primary.main) }} />
                  ) : (
                    <Typography style={{ fontWeight: 'bold' }} align="center" variant="h5">
                      <span
                        style={{
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img
                          src={couponImg}
                          alt=""
                          style={{
                            color: '#FFF',
                            width: '2rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '1rem',
                          }}
                        />
                        {storeInfo.type === 'product' && storeInfo
                          ? storeInfo.voucherPaymentTemplates
                            ? storeInfo.buttonLabel || 'Resgatar com voucher'
                            : totalPoints < storeInfo.cost
                            ? `Faltam ${storeInfo.cost - totalPoints} pontos`
                            : `Resgatar produto: ${storeInfo.cost} pontos`
                          : storeInfo.buttonLabel}
                      </span>
                    </Typography>
                  )}
                </Button>
              </>
            ) : storeInfo.autoValidate === undefined && storeInfo.showQRCode === undefined ? (
              <QRCode
                id="QRCode"
                value={qrCode}
                size={140}
                bgColor={'#ffffff'}
                fgColor={'#000000'}
                level={'M'}
              />
            ) : (
              <Button
                fullWidth={true}
                style={{
                  background: infoSeuClube ? infoSeuClube.colorPrimary : colorPrimary,
                  fontWeight: 900,
                  color: infoSeuClube ? infoSeuClube.colorSecondary : colorSecondary,
                  textTransform: 'none',
                  marginTop: '40px',
                }}
                color="primary"
                variant="contained"
                component="span"
                disabled={loadingOrder || loadingStoreData}
                onClick={() => {
                  orderStore('promotion');
                  trackEventMatomo('Produto', 'tocar', 'botao', 'Resgatar produto!');
                }}
              >
                {loadingOrder || loadingStoreData ? (
                  <CircularProgress style={{ color: colorText(theme.palette.primary.main) }} />
                ) : (
                  <Typography style={{ fontWeight: 'bold' }} align="center" variant="h5">
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={couponImg}
                        alt=""
                        style={{
                          color: '#FFF',
                          width: '2rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: '1rem',
                        }}
                      />
                      {storeInfo.buttonLabel ? storeInfo.buttonLabel : t('general.rescue_coupon')}
                    </span>
                  </Typography>
                )}
              </Button>
            )}
          </div>

          {storeInfo.showGoToPlace ? (
            <div style={{ marginTop: 15, textAlign: 'center' }}>
              <Link
                to={{ pathname: '/estabelecimento/', search: '?id=' + storeInfo.establishmentId }}
                style={{ textDecoration: 'none', color: 'black' }}
                align="center"
              >
                <Button
                  style={{
                    fontWeight: 900,
                    color: infoSeuClube ? infoSeuClube.colorSecondary : colorSecondary,
                    textTransform: 'none',
                  }}
                  color="secondary"
                >
                  {storeInfo.goToPlaceLabel
                    ? storeInfo.goToPlaceLabel
                    : t('general.go_to_establishment')}
                </Button>
              </Link>
            </div>
          ) : null}
        </div>
      )}

      <Dialog open={orderReply !== null} onClose={() => setOrderReply(null)}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => setOrderReply(null)} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogTitle>
          {orderReply === true && t('general.thanks')}
          {orderReply === false && t('errors.something_went_wrong')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {orderReply === true && t('general.your_redemption_request_has_been_made')}
            {orderReply === false && t('errors.try_later')}
          </DialogContentText>
        </DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
            padding: '1rem 0',
          }}
        >
          <Button
            onClick={() => setOrderReply(null)}
            variant="contained"
            color="primary"
            style={{ fontWeight: '700' }}
          >
            Fechar
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

