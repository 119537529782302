import React, { useEffect } from 'react';

import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trackEventMatomo, trackEventMatomoVisit } from 'js/library/utils/helpers';

export default function RegistrationUnavailable() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    trackEventMatomoVisit('/registration-unavailable');
  }, []);

  return (
    <S.Container className="paper-container">
      <h1>{t('RegistrationUnavailable.message')}</h1>
      <S.Button
        onClick={() => {
          navigate('/');
          trackEventMatomo('registrationUnavailable', 'tocar', 'button', 'voltar');
        }}
      >
        {t('general.back')}
      </S.Button>
    </S.Container>
  );
}

