import { useEffect } from 'react';
import { getCardsByOrgs } from 'js/library/utils/API/getCardsByOrgs';
import { getLastUserInfo } from 'js/library/utils/helpers';
import { useAppContext } from './AppProvider';

export default function useGetCardsHomeClub() {
  const {
    cards: { cards, setCards, orgs, setOrgs, pureOrgs, setPureOrgs, uid, setUid },
  } = useAppContext();

  useEffect(() => {
    const userInfo = getLastUserInfo();
    if (!userInfo?.uId) return;

    const currentUid = userInfo.uId;

    if (cards && uid === currentUid) return;

    const seuClubeInfos = sessionStorage.getItem('seuClubeInfos');
    if (!seuClubeInfos) return;

    const orgBySeuClube = JSON.parse(seuClubeInfos)?.orgs?.[0];
    if (!orgBySeuClube) return;

    getCardsByOrgs(currentUid, orgBySeuClube).then((result) => {
      if (result.success) {
        const newPureOrgs = result.logos;
        const newCards = {};
        const newOrgs = result.logos.filter((org) => {
          let hasCards = 0;
          result.cards
            .filter((card) => card.orgs.includes(org.orgID))
            .forEach((card) => {
              hasCards++;
              if (!newCards[org.orgID]) {
                newCards[org.orgID] = [];
              }
              newCards[org.orgID].push(card);
            });

          return hasCards > 0;
        });

        setPureOrgs(newPureOrgs);
        setCards(newCards);
        setOrgs(newOrgs);
        setUid(currentUid); 
      }
    });
  }, [cards, uid, setCards, setOrgs, setPureOrgs, setUid]);

  return { cards, orgs, pureOrgs, setOrgs, setCards };
}
