import { CircularProgress } from '@mui/material';
import { theme } from 'index';
import React from 'react';

export const Loading = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'block',
        margin: 'auto',
        backgroundColor: '#4b4b4b',
        textAlign: 'center',
      }}
    >
      <div width="350px" style={{ paddingTop: '35vh' }}>
        <CircularProgress style={{ color: theme.palette.primary.main }} size="3rem" />
      </div>
    </div>
  );
};
export default Loading;

