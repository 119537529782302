import axios from 'axios';
import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../cfac22/cfac22';

export function addAccessList(payload) {
  return new Promise((resolve, reject) => {
    authToken()
      .then((token) => {
        const config = {
          method: 'POST',
          url: `${cfac22('API_HOST_V2')}/seuClube/addAccessList`,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Full-Url': window.location.href,
          },
          data: payload,
        };

        axios(config)
          .then((response) => {
            resolve(response.data.result);
          })
          .catch((error) => {
            reject(error.response ? error.response.data : error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

