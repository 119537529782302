import React, { useState } from 'react';
import Modal from 'react-modal';

import './uploadUserPhoto.js';
import closeImg from 'styles/assets/close.svg';
import imageUserDefault from '../../../../styles/assets/avatar/imageDefaultSI.png';
import { Container, Form } from './uploadUserPhoto.js';

export function UploadUserPhotos({ isOpen, onRequestClose }) {
  const [userImage, setUserImage] = useState('');
  const uploadImage = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', userImage);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button type="button" onClick={onRequestClose} className="react-modal-close">
        <img src={closeImg} alt="Fechar modal" />
      </button>
      <Container>
        <p>Foto do perfil</p>
        <Form onSubmit={uploadImage}>
          {userImage ? (
            <img src={URL.createObjectURL(userImage)} alt="Imagem Escolhida" />
          ) : (
            <img src={imageUserDefault} alt="Imagem default" />
          )}
          <input type="file" name={userImage} onChange={(e) => setUserImage(e.target.files[0])} />
          <button type="submit">Salvar</button>
        </Form>
      </Container>
    </Modal>
  );
}

